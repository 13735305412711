import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
  RouterOutlet
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';
import { SharedService } from './marketplace/service/shared.service';
import { TagManagerService } from './marketplace/service/tag-manager.service';
import { UserPreferenceService } from './marketplace/service/user-preferences.service';
import { BreakpointService } from './shared/services/breakpoint.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterModule, RouterOutlet]
})
export class AppComponent {
  title = 'hello Anybuddy';
  deviceInfo = null;
  isMobileResolution: boolean;

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    private tagManager: TagManagerService,
    private authFirebaseService: AuthFirebaseService,
    private breakpointService: BreakpointService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.pushScreenView(this.router.url);
    this.router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        this.pushScreenView(y.urlAfterRedirects);
      }
    });
    this.translate.addLangs(['fr', 'es', 'nl', 'en', 'be', 'ch']);
    this.translate.setDefaultLang('fr');
    this.userPreferenceService.init();

    this.epicFunction();
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.sharedService.emitChange(this.isMobileResolution);
  }

  pushScreenView(screenName: string): void {
    this.tagManager.pushScreenView({
      screenName: screenName,
      user_id: this.authFirebaseService.getUserDataId()
    });
  }

  epicFunction(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  onResize(event): void {
    if (
      event.target.innerWidth <= 768 ||
      this.deviceService.isMobile() ||
      this.deviceService.isTablet()
    ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.sharedService.emitChange(this.isMobileResolution);
  }

  onActivate(): void {
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }
}
